/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CBEM() {
  const [brokerExecModel, setBrokerExecModel] = useState({
    broker_execution_model: '',
    trade_span: '',
    broker: '',
    trade_regect_delay: '',
    currency: '',
    enable: '',
    trade_limit_request: '',
    spread: '',
    copy_from: '',
    hub_aggregation_model: '',
    description: '',
    enable1: '',
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      axios.post('http://localhost:8080/kubri/server/index.php/broker_exec_model', brokerExecModel);
      console.log(brokerExecModel);
      toast('Form submitted successfully!');
      setBrokerExecModel({
        broker_execution_model: '',
        trade_span: '',
        broker: '',
        trade_regect_delay: '',
        currency: '',
        enable: '',
        trade_limit_request: '',
        spread: '',
        copy_from: '',
        hub_aggregation_model: '',
        description: '',
        enable1: '',
      });
    } catch (error) {
      console.error(error);
      alert('Failed to submit form data');
    }
  };
  const handleChange = (e) => {
    if (e.target.type === 'checkbox') {
      setBrokerExecModel({ ...brokerExecModel, [e.target.name]: e.target.checked });
    } else {
      setBrokerExecModel({ ...brokerExecModel, [e.target.name]: e.target.value });
    }
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <ToastContainer />
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3">
            <label>Broker Execution Model</label>
            <input value={brokerExecModel.broker_execution_model} onChange={handleChange} name="broker_execution_model" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Trade Span</label>
            <input value={brokerExecModel.trade_span} onChange={handleChange} name="trade_span" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Broker</label>
            <div className="relative">
              <select value={brokerExecModel.value} onChange={handleChange} name="broker" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                <option value="*,">*,</option>
                <option value="*,">*,</option>
                <option value="*,">*,</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Trade Regect delay</label>
            <input value={brokerExecModel.trade_regect_delay} onChange={handleChange} name="trade_regect_delay" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Currency</label>
            <input value={brokerExecModel.currency} onChange={handleChange} name="currency" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3 mt-8">
            <input value={brokerExecModel.enable} onChange={handleChange} name="enable" id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Enable</label>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Trade Limit Request</label>
            <input value={brokerExecModel.trade_limit_request} onChange={handleChange} name="trade_limit_request" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Spread</label>
            <div className="relative">
              <select value={brokerExecModel.spread} onChange={handleChange} name="spread" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                <option>*,</option>
                <option>*,</option>
                <option>*,</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Copy From</label>
            <div className="relative">
              <select value={brokerExecModel.value} onChange={handleChange} name="copy_from" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                <option value="*,">*,</option>
                <option value="*,">*,</option>
                <option value="*,">*,</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Hub Aggregation Model</label>
            <div className="relative">
              <select value={brokerExecModel.value} onChange={handleChange} name="hub_aggregation_model" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                <option value="*,">*,</option>
                <option value="*,">*,</option>
                <option value="*,">*,</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Description</label>
            <textarea value={brokerExecModel.description} onChange={handleChange} name="description" rows="5" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" placeholder="Symbols..." />
          </div>
          <div className="w-full md:w-1/2 px-3 mt-8">
            <input value={brokerExecModel.enable1} onChange={handleChange} name="enable1" id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Enable</label>
          </div>
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-8">
            Submit
          </button>
          <button className="bg-blue-500 hover:bg-blue-700 text-white ml-5 font-bold py-2 px-4 rounded mt-8">
            Cancel
          </button>
          <button className="bg-gray-500 hover:bg-gray-700 text-white ml-5 font-bold py-2 px-4 rounded mt-8">
            Reset
          </button>
        </div>
      </form>
    </div>
  );
}

export default CBEM;
