import React, { Fragment } from 'react';
import { Tab } from '@headlessui/react';
import { Header } from '../components';
import ListBrokerFeeds from '../components/ListBrokerFeeds';
import CBrokerFeeds from '../components/CBrokerFeeds';

function BrokerFeeds() {
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="" title="Broker Feeds" />
      <Tab.Group>
        <Tab.List>
          <Tab as={Fragment}>
            {({ selected }) => (
              <div className={selected ? 'bg-customBlue border rounded-2xl p-4 text-white dark:text-gray-200 inline-block' : 'bg-white border rounded-2xl hover:bg-light-gray  p-4 text-grey  focus:border-gray-500 dark:text-gray-200 inline-block'}>
                List
              </div>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <div className={selected ? 'bg-customBlue border rounded-2xl p-4 text-white dark:text-gray-200 inline-block' : 'bg-white border rounded-2xl hover:bg-light-gray  p-4 text-grey  focus:border-gray-500 dark:text-gray-200 inline-block'}>
                Create a Broker Feed
              </div>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel><ListBrokerFeeds /></Tab.Panel>
          <Tab.Panel><CBrokerFeeds /></Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default BrokerFeeds;
