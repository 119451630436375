/* eslint-disable no-console */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';

import { listsBEMGrid } from '../data/dummy';

const ListBEM = () => {
  const [brokerExecModel, setBrokerExecModel] = useState([]);
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['Delete'];
  const editing = { allowDeleting: true, allowEditing: true };

  useEffect(() => {
    // axios.get('http://newtest.kubri.tech/server/index.php/spread')
    axios.get('http://localhost:8080/kubri/server/index.php/broker_exec_model')
      .then(response => {
        setBrokerExecModel(response.data);
      })
      .catch(error => {
        console.error('Error fetching broker feed data:', error);
      });
  }, []);
  return (
    <div>
      <GridComponent
        dataSource={brokerExecModel}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        editSettings={editing}
        allowSorting
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {listsBEMGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Page, Resize, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default ListBEM;

