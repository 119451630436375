/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CLPApiLink() {
  const [lpApiLink, setLpApiLink] = useState({
    lp_id: '',
    symbols: '',
    lp: '',
    shared_id: '',
    taker_execution_model: '',
    sides: '',
    hub_aggregation_model: '',
    ord_types: '',
    securities: '',
    priority: '',
    enable: '',
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // await axios.post('http://newtest.kubri.tech/server/index.php/lpApiLink', lpApiLink);
      await axios.post('http://localhost:8080/kubri/server/index.php/lpApiLink', lpApiLink);
      toast.success('Data submitted successfully!');
      setLpApiLink({
        lp_id: '',
        symbols: '',
        lp: '',
        shared_id: '',
        taker_execution_model: '',
        sides: '',
        hub_aggregation_model: '',
        ord_types: '',
        securities: '',
        priority: '',
        enable: '',
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit form data');
    }
  };
  const handleChange = (e) => {
    if (e.target.type === 'checkbox') {
      setLpApiLink({ ...lpApiLink, [e.target.name]: e.target.checked });
    } else {
      setLpApiLink({ ...lpApiLink, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <ToastContainer />
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3">
            <label>ID</label>
            <input value={lpApiLink.lp_id} onChange={handleChange} name="lp_id" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Sides</label>
            <div className="relative">
              <select value={lpApiLink.value} onChange={handleChange} name="sides" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required id="grid-state">
                <option value="*,">*,</option>
                <option value="*,">*,</option>
                <option value="*,">*,</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Symbols</label>
            <textarea value={lpApiLink.symbols} onChange={handleChange} name="symbols" rows="5" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required placeholder="Symbols..." />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>LP</label>
            <textarea value={lpApiLink.lp} onChange={handleChange} name="lp" rows="5" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required placeholder="Takers..." />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Shared IDs</label>
            <textarea value={lpApiLink.shared_id} onChange={handleChange} name="shared_id" rows="5" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required placeholder="Share IDs..." />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label name="w-full">Taker Execution Models</label>
            <textarea value={lpApiLink.taker_execution_model} onChange={handleChange} name="taker_execution_model" rows="5" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required placeholder="Taker Execution Models..." />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label name="w-full">Hub Aggregation Models</label>
            <textarea value={lpApiLink.hub_aggregation_model} onChange={handleChange} name="hub_aggregation_model" rows="5" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required placeholder="Hub Aggregation Models..." />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Securities</label>
            <textarea value={lpApiLink.securities} onChange={handleChange} name="securities" rows="5" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required placeholder="Securities..." />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label name="w-full">Ord Types</label>
            <div className="relative">
              <select value={lpApiLink.value} onChange={handleChange} name="ord_types" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required id="grid-state">
                <option value="*,">*,</option>
                <option value="*,">*,</option>
                <option value="*,">*,</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Priority</label>
            <div className="relative">
              <select value={lpApiLink.priority} onChange={handleChange} name="priority" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required id="grid-state">
                <option value="*,">*,</option>
                <option value="*,">*,</option>
                <option value="*,">*,</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg name="enable" className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3 mt-8">
            <input value={lpApiLink.enable} onChange={handleChange} name="enable" id="default-checkbox" required type="checkbox" />
            <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Enable</label>
          </div>
        </div>
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Submit
        </button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white ml-5 font-bold py-2 px-4 rounded">
          Cancel
        </button>
        <button className="bg-gray-500 hover:bg-gray-700 text-white ml-5 font-bold py-2 px-4 rounded">
          Reset
        </button>
      </form>
    </div>
  );
}

export default CLPApiLink;
