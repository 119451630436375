/* eslint-disable no-console */
/* eslint-disable arrow-parens */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';

import { listsLpSessionGrid } from '../data/dummy';

const ListLPSession = () => {
  const [lpSessionData, setLpSessionData] = useState([]);
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['Delete'];
  const editing = { allowDeleting: true, allowEditing: true };

  useEffect(() => {
    axios.get('http://test.kubri.tech/server/index.php/lpSession')
    // axios.get('http://localhost:8080/kubri/server/index.php/lpSession')
      .then(response => {
        setLpSessionData(response.data);
      })
      .catch(error => {
        console.error('Error fetching LP session Data', error);
      });
  }, []);
  return (
    <div>
      <GridComponent
        dataSource={lpSessionData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        editSettings={editing}
        allowSorting
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {listsLpSessionGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Page, Resize, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default ListLPSession;

// Will use this after selecting the above table
// import React from 'react';
// import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';

// import { listsData, listsGrid } from '../data/dummy';

// const ListLPSession = () => {
//   const selectionsettings = { persistSelection: true };
//   const toolbarOptions = ['Delete'];
//   const editing = { allowDeleting: true, allowEditing: true };

//   return (
//     <div>
//       <GridComponent
//         dataSource={listsData}
//         enableHover={false}
//         allowPaging
//         pageSettings={{ pageCount: 5 }}
//         selectionSettings={selectionsettings}
//         toolbar={toolbarOptions}
//         editSettings={editing}
//         allowSorting
//       >
//         <ColumnsDirective>
//           {/* eslint-disable-next-line react/jsx-props-no-spreading */}
//           {listsGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
//         </ColumnsDirective>
//         <Inject services={[Page, Resize, Selection, Toolbar, Edit, Sort, Filter]} />
//       </GridComponent>
//     </div>
//   );
// };

// export default ListLPSession;

