/* eslint-disable no-console */
/* eslint-disable arrow-parens */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Page, Selection, Inject, Edit, Toolbar, Sort, Filter, DetailRow } from '@syncfusion/ej2-react-grids';
import { hubAggregationModelGrid, HubAggregationGrid } from '../data/dummy';

const ListHAM = () => {
  const [hubAggregationModelData, setHubAggregationModelData] = useState([]);

  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['Delete'];
  const editing = { allowDeleting: true, allowEditing: true };

  useEffect(() => {
    axios.get('http://test.kubri.tech/server/index.php/hub_aggregation_model')
    // axios.get('http://localhost:8080/kubri/server/index.php/hub_aggregation_model')
      .then(response => {
        setHubAggregationModelData(response.data);
      })
      .catch(error => {
        console.error('Error fetching Hub aggregation models data:', error);
      });
  }, []);
  const detailTemplate = (data) => {
    const [detailData, setDetailData] = useState([]);
    useEffect(() => {
      axios.get('http://test.kubri.tech/server/index.php/hub_aggregation_models_1')
      // axios.get('http://localhost:8080/kubri/server/index.php/hub_aggregation_models_1')
        .then(response => {
          setDetailData(response.data);
        })
        .catch(error => {
          console.error('Error fetching detail data:', error);
        });
    }, [data]);

    return (
      <GridComponent
        dataSource={detailData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        editSettings={editing}
        allowSorting
      >
        {/* Define columns for the detail grid here */}
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {HubAggregationGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Page, Resize, Selection, Toolbar, Edit, Sort, Filter, DetailRow]} />
      </GridComponent>
    );
  };
  return (
    <div>
      <GridComponent
        dataSource={hubAggregationModelData}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        editSettings={editing}
        detailTemplate={detailTemplate}
        allowSorting
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {hubAggregationModelGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Page, Resize, Selection, Toolbar, Edit, Sort, Filter, DetailRow]} />
      </GridComponent>
    </div>
  );
};

export default ListHAM;

// This code will use when we expand the data item
// /* eslint-disable arrow-parens */
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';

// import { HubAggregationGrid } from '../data/dummy';

// const ListHAM = () => {
//   const [hubAggregationModelData, setHubAggregationModelData] = useState([]);

//   const selectionsettings = { persistSelection: true };
//   const toolbarOptions = ['Delete'];
//   const editing = { allowDeleting: true, allowEditing: true };

//   useEffect(() => {
//     axios.get('http://localhost:8080/kubri/server/index.php/hub_aggregation_model')
//       .then(response => {
//         setHubAggregationModelData(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching Hub aggregation models data:', error);
//       });
//   }, []);
//   return (
//     <div>
//       <GridComponent
//         dataSource={hubAggregationModelData}
//         enableHover={false}
//         allowPaging
//         pageSettings={{ pageCount: 5 }}
//         selectionSettings={selectionsettings}
//         toolbar={toolbarOptions}
//         editSettings={editing}
//         allowSorting
//       >
//         <ColumnsDirective>
//           {/* eslint-disable-next-line react/jsx-props-no-spreading */}
//           {HubAggregationGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
//         </ColumnsDirective>
//         <Inject services={[Page, Resize, Selection, Toolbar, Edit, Sort, Filter]} />
//       </GridComponent>
//     </div>
//   );
// };

// export default ListHAM;

