/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ImportUniversalSymbols = () => {
  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    event.preventDefault();
    setFile(event.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error('Please select a file to upload!');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post('http://test.kubri.tech/server/index.php/universal', formData);
      // await axios.post('http://localhost:8080/kubri/server/index.php/universal', formData);
      // Handle success
      console.log(formData);
      console.log('File uploaded successfully!');
      toast.success('File uploaded successfully!');
    } catch (error) {
      // Handle error
      console.error('Error uploading the file:', error);
      toast.error(`Error uploading the file: ${error.message}`);
    }
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <form className="w-full max-w-lg">
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-2/2 px-3">
            <label>Import Symbols List</label>
            <input onChange={handleChange} name="symbols" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="file" />
          </div>
          <button onClick={handleUpload} type="submit" className="bg-blue-500 mt-5 items-center hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Submit
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ImportUniversalSymbols;
