/* eslint-disable no-console */
/* eslint-disable arrow-parens */
/* eslint-disable comma-spacing */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Login } from './pages';
import Home from './pages/Home';

function App() {
  const [authUser, setAuthUser] = useState('');
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    console.log('Stored Token:', authToken);
    if (authToken) {
      // Verify the token on the server
      axios.post('http://test.kubri.tech/kubri/server/index.php/verify_token', {
      // axios.post('http://localhost:8080/kubri/server/index.php/verify_token', {
        token: authToken,
      })
        .then(response => {
          if (response.data.success) {
            console.log('Token Verified');
            setAuthUser(authToken); // Set the user as the token
          } else {
            localStorage.removeItem('authToken');
          }
        })
        .catch(error => {
          console.log(error);
          localStorage.removeItem('authToken');
        });
    }
  }, []);
  console.log('authUser:', authUser);
  return (
    <div>
      {(authUser) ? <Home setUser={setAuthUser} /> : <Login setUser={setAuthUser} />}
    </div>
  );
}

export default App;
