/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CLPSession = () => {
  const [sessionData, setSessionData] = useState({
    maker_session_type: '',
    maker_category: '',
    server_address: '',
    sender_comp_id: '',
    target_comp_id: '',
    shared_id: '',
    user_name: '',
    password: '',
    account: '',
    session_qualifier: '',
    currency: '',

  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //  await axios.post('http://newtest.kubri.tech/server/index.php/lpSession', sessionData);
      await axios.post('http://localhost:8080/kubri/server/index.php/lpSession', sessionData);
      toast.success('Data submitted successfully!');
      setSessionData({
        maker_session_type: '',
        maker_category: '',
        server_address: '',
        sender_comp_id: '',
        target_comp_id: '',
        shared_id: '',
        user_name: '',
        password: '',
        account: '',
        session_qualifier: '',
        currency: '',
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit data');
    }
  };

  const handleChange = (e) => {
    setSessionData({ ...sessionData, [e.target.name]: e.target.value });
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <ToastContainer />
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3">
            <label>Maker Session type</label>
            <div className="relative">
              <select value={sessionData.value} onChange={handleChange} name="maker_session_type" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required id="grid-state">
                <option value="new">New</option>
                <option value="old">old</option>
                <option value="advanced">advanced</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 px-3">
            <label>User Name</label>
            <input value={sessionData.user_name} onChange={handleChange} name="user_name" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Maker Category</label>
            <div className="relative">
              <select value={sessionData.value} onChange={handleChange} name="maker_category" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required id="grid-state">
                <option value="new">New</option>
                <option value="old">old</option>
                <option value="advanced">advanced</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Password</label>
            <input value={sessionData.password} onChange={handleChange} name="password" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Server Address</label>
            <input value={sessionData.serverAddress} onChange={handleChange} name="server_address" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Account</label>
            <input value={sessionData.account} onChange={handleChange} name="account" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Sender CompId</label>
            <input value={sessionData.sender_comp_id} onChange={handleChange} name="sender_comp_id" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Session Qualifier</label>
            <input value={sessionData.session_qualifier} onChange={handleChange} name="session_qualifier" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Target CompId</label>
            <input value={sessionData.target_comp_id} onChange={handleChange} name="target_comp_id" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Shared Id</label>
            <input value={sessionData.shared_id} onChange={handleChange} name="shared_id" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Currency</label>
            <input value={sessionData.currency} onChange={handleChange} name="currency" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3 mt-8">
            <input id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Reset On Logon</label>
            <input id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">SSL Protocol</label>
          </div>
        </div>
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Submit
        </button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white ml-5 font-bold py-2 px-4 rounded">
          Cancel
        </button>
        <button className="bg-gray-500 hover:bg-gray-700 text-white ml-5 font-bold py-2 px-4 rounded">
          Reset
        </button>
      </form>
    </div>
  );
};

export default CLPSession;
