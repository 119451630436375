/* eslint-disable import/newline-after-import */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function CSpread() {
  const [spread, setSpread] = useState({
    markup_model: '',
    bid_points: '',
    ask_points: '',
    enable: '',
    description: '',
    multiplier_1: '',
    multiplier_2: '',
    multiplier_3: '',
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://test.kubri.tech/server/index.php/spread', spread);
      // await axios.post('http://localhost:8080/kubri/server/index.php/spread', spread);
      toast.success('Form submitted successfully!');
      setSpread({
        markup_model: '',
        bid_points: '',
        ask_points: '',
        enable: '',
        description: '',
        multiplier_1: '',
        multiplier_2: '',
        multiplier_3: '',
      });
    } catch (error) {
      console.error(error);
      alert('Failed to submit form data');
    }
  };
  const handleChange = (e) => {
    if (e.target.type === 'checkbox') {
      setSpread({ ...spread, [e.target.name]: e.target.checked });
    } else {
      setSpread({ ...spread, [e.target.name]: e.target.value });
    }
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <ToastContainer />
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3">
            <label>Markup Model</label>
            <input value={spread.markup_model} onChange={handleChange} name="markup_model" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Bid Points</label>
            <input value={spread.bid_points} onChange={handleChange} name="bid_points" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Multiplier 1</label>
            <input value={spread.multiplier_1} onChange={handleChange} name="multiplier_1" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Ask Points</label>
            <input value={spread.ask_points} onChange={handleChange} name="ask_points" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Multiplier 2</label>
            <input value={spread.multiplier_2} onChange={handleChange} name="multiplier_2" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Description</label>
            <input value={spread.description} onChange={handleChange} name="description" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Multiplier 3</label>
            <input value={spread.multiplier_3} onChange={handleChange} name="multiplier_3" className="w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Copy From</label>
            <div className="relative">
              <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required id="grid-state">
                <option>New</option>
                <option>Missouri</option>
                <option>Texas</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Enable</label>
            <div className="w-full md:w-1/2 px-3 mt-2">
              <input value={spread.enable} onChange={handleChange} name="enable" id="default-checkbox" required type="checkbox" />
              <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Enable</label>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label>Enable Multiplier</label><br />
            <input id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Multiplier 1</label>
            <input id="default-checkbox" className="ml-2" type="checkbox" />
            <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Multiplier 2</label><br />
            <input id="default-checkbox" type="checkbox" />
            <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Multiplier 3</label>
          </div>
        </div>
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Submit
        </button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white ml-5 font-bold py-2 px-4 rounded">
          Cancel
        </button>
        <button className="bg-gray-500 hover:bg-gray-700 text-white ml-5 font-bold py-2 px-4 rounded">
          Reset
        </button>
      </form>
    </div>
  );
}

export default CSpread;
